import {
  Block,
  BondAlt,
  Clock,
  Calculator,
  CashCoins,
  ChartArcs1,
  ChartBar,
  CoinEuro,
  Configuration,
  IconHide,
  IconUser,
  ListNumber,
  Trophy,
  UserGroup,
  PlayL,
  IconTelegram,
  Bell,
} from '@vilibets/vilibets-uikit/dist/atoms/Icons';
import DashboardIcon from '@vilibets/vilibets-uikit/dist/atoms/Icons/Dashboard';

export const header_links = (user) => {
  const header_links_users = [
    {
      category: 'Dashboard',
      links: [
        {
          name: 'Dashboard',
          icon: <DashboardIcon />,
          route: '/',
        },
      ],
    },
    {
      category: 'Aplicaciones',
      links: [
        {
          name: 'Bienvenidas',
          icon: <Clock />,
          route: '/welcome-bonds/available',
        },
        {
          name: 'Bonos',
          icon: <BondAlt />,
          route: '/bonds/available',
        },
        {
          name: 'ProfitTracker',
          icon: <ChartBar />,
          route: '/profit-tracker',
        },
        {
          name: 'OddsMatcher',
          icon: <CashCoins />,
          route: 'oddsmatcher',
        },
        {
          name: 'Dutcher',
          icon: <ChartArcs1 />,
          route: 'dutcher',
        },
        {
          name: 'Calculadoras',
          icon: <Calculator />,
          route: 'calculators',
          submenu: [
            { name: 'Calculadora normal', route: 'calculators/normal', icon: <Calculator /> },
            { name: 'Calc. Dutching', route: 'calculators/dutching', icon: <Calculator /> },
            { name: 'Calc. Sin Riesgo Rembolso', route: 'calculators/risk-free', icon: <Calculator /> },
            { name: 'Calc. Acumuladas', route: 'calculators/accumulated', icon: <Calculator /> },
            { name: 'Calc. F-F Reembolso', route: 'calculators/ff-refound', icon: <Calculator /> },
            { name: 'Calc. 3-F Reembolso', route: 'calculators/triple-refound', icon: <Calculator /> },
            { name: 'Calc. Acumuladas F-F', route: 'calculators/ff-accumulated', icon: <Calculator /> },
          ],
        },
        {
          name: 'Avisos',
          icon: <Bell />,
          route: 'notices',
        },
        {
          name: 'Curso',
          icon: <PlayL />,
          route: 'course',
        },
        {
          name: 'Telegram',
          icon: <IconTelegram />,
          route: '/settings/telegram',
        },
        {
          name: 'Incidencias',
          icon: <Block />,
          route: 'incidents',
        },
        {
          name: 'Afiliados',
          icon: <UserGroup />,
          route: '/manage-affiliates',
        },
        {
          name: 'Guias',
          icon: <ListNumber />,
          route: 'guide',
        },
      ],
    },
    {
      category: 'Configuración',
      links: [
        {
          name: 'Suscripción',
          icon: <CoinEuro />,
          route: 'subscriptions/my-subscription',
        },
        {
          name: 'Configuración',
          icon: <Configuration />,
          route: 'settings',
        },
        {
          name: 'Gestión de bookies',
          icon: <IconHide />,
          route: 'manage-bookmarkers',
        },
      ],
    },
  ];

  const header_links_workers = [
    {
      category: 'Dashboard',
      links: [
        {
          name: 'Dashboard',
          icon: <DashboardIcon />,
          route: '/',
        },
      ],
    },
    {
      category: 'Aplicaciones',
      links: [
        {
          name: 'Bienvenidas',
          icon: <Clock />,
          route: '/welcome-bonds/available',
        },
        {
          name: 'Bonos',
          icon: <BondAlt />,
          route: '/bonds/available',
        },
        {
          name: 'ProfitTracker',
          icon: <ChartBar />,
          route: '/profit-tracker',
        },
        {
          name: 'OddsMatcher',
          icon: <CashCoins />,
          route: 'oddsmatcher',
        },
        {
          name: 'Dutcher',
          icon: <ChartArcs1 />,
          route: 'dutcher',
        },
        {
          name: 'Calculadoras',
          icon: <Calculator />,
          route: '/calculators',
          submenu: [
            { name: 'Calculadora normal', route: 'calculators/normal', icon: <Calculator /> },
            { name: 'Calc. Dutching', route: 'calculators/dutching', icon: <Calculator /> },
            { name: 'Calc. Sin Riesgo Rembolso', route: 'calculators/risk-free', icon: <Calculator /> },
            { name: 'Calc. Acumuladas', route: 'calculators/accumulated', icon: <Calculator /> },
            { name: 'Calc. F-F Reembolso', route: 'calculators/ff-refound', icon: <Calculator /> },
            { name: 'Calc. 3-F Reembolso', route: 'calculators/triple-refound', icon: <Calculator /> },
            { name: 'Calc. Acumuladas F-F', route: 'calculators/ff-accumulated', icon: <Calculator /> },
          ],
        },
        {
          name: 'Avisos',
          icon: <Bell />,
          route: 'notices',
        },
        {
          name: 'Curso',
          icon: <PlayL />,
          route: 'course',
        },
        {
          name: 'Telegram',
          icon: <IconTelegram />,
          route: '/settings/telegram',
        },
        {
          name: 'Incidencias',
          icon: <Block />,
          route: 'incidents',
        },
        {
          name: 'Afiliados',
          icon: <UserGroup />,
          route: '/manage-affiliates',
        },
        {
          name: 'Guias',
          icon: <ListNumber />,
          route: 'guide',
        },
      ],
    },
    {
      category: 'Configuración',
      links: [
        {
          name: 'Suscripción',
          icon: <CoinEuro />,
          route: 'subscriptions/my-subscription',
        },
        {
          name: 'Configuración',
          icon: <Configuration />,
          route: 'settings',
        },
        {
          name: 'Gestión de bookies',
          icon: <IconHide />,
          route: 'manage-bookmarkers',
        },
      ],
    },
    {
      category: 'Usuario trabajador',
      links: [
        {
          name: 'Gestión de Bonos',
          icon: <BondAlt />,
          route: '/manage-bonds',
        },
      ],
    },
  ];

  const header_links_admins = [
    {
      category: 'Dashboard',
      links: [
        {
          name: 'Dashboard',
          icon: <DashboardIcon />,
          route: '/',
        },
      ],
    },
    {
      category: 'Aplicaciones',
      links: [
        {
          name: 'Bienvenidas',
          icon: <Clock />,
          route: '/welcome-bonds/available',
        },
        {
          name: 'Bonos',
          icon: <BondAlt />,
          route: '/bonds/available',
        },
        {
          name: 'ProfitTracker',
          icon: <ChartBar />,
          route: '/profit-tracker',
        },
        {
          name: 'OddsMatcher',
          icon: <CashCoins />,
          route: 'oddsmatcher',
        },
        {
          name: 'Dutcher',
          icon: <ChartArcs1 />,
          route: 'dutcher',
        },
        {
          name: 'Calculadoras',
          icon: <Calculator />,
          route: 'calculators',
          submenu: [
            { name: 'Calculadora normal', route: 'calculators/normal', icon: <Calculator /> },
            { name: 'Calc. Dutching', route: 'calculators/dutching', icon: <Calculator /> },
            { name: 'Calc. Sin Riesgo Rembolso', route: 'calculators/risk-free', icon: <Calculator /> },
            { name: 'Calc. Acumuladas', route: 'calculators/accumulated', icon: <Calculator /> },
            { name: 'Calc. F-F Reembolso', route: 'calculators/ff-refound', icon: <Calculator /> },
            { name: 'Calc. 3-F Reembolso', route: 'calculators/triple-refound', icon: <Calculator /> },
            { name: 'Calc. Acumuladas F-F', route: 'calculators/ff-accumulated', icon: <Calculator /> },
          ],
        },
        {
          name: 'Avisos',
          icon: <Bell />,
          route: 'notices',
        },
        {
          name: 'Curso',
          icon: <PlayL />,
          route: 'course',
        },
        {
          name: 'Telegram',
          icon: <IconTelegram />,
          route: '/settings/telegram',
        },
        {
          name: 'Incidencias',
          icon: <Block />,
          route: 'incidents',
        },
        {
          name: 'Afiliados',
          icon: <UserGroup />,
          route: '/manage-affiliates',
        },
        {
          name: 'Guias',
          icon: <ListNumber />,
          route: 'guide',
        },
      ],
    },
    {
      category: 'Configuración',
      links: [
        {
          name: 'Suscripción',
          icon: <CoinEuro />,
          route: 'subscriptions/my-subscription',
        },
        {
          name: 'Configuración',
          icon: <Configuration />,
          route: 'settings',
        },
        {
          name: 'Gestión de bookies',
          icon: <IconHide />,
          route: 'manage-bookmarkers',
        },
      ],
    },
    {
      category: 'Administración',
      links: [
        {
          name: 'Usuarios',
          icon: <UserGroup />,
          route: 'users?role=user',
        },
        {
          name: 'Gestión de avisos',
          icon: <Bell />,
          route: 'manage-notices',
        },
        {
          name: 'Trabajadores',
          icon: <IconUser />,
          route: 'workers',
        },
        {
          name: 'Influencers',
          icon: <CashCoins />,
          route: 'influencers',
        },
        {
          name: 'Facturación',
          icon: <CoinEuro />,
          route: 'orders',
        },
        {
          name: 'Casas de apuestas',
          icon: <Trophy />,
          route: 'bookmarkers',
        },
        {
          name: 'Gestión de incidencias',
          icon: <Block />,
          route: 'manage-incidents',
        },
        {
          name: 'Gestión de Bonos',
          icon: <BondAlt />,
          route: '/manage-bonds',
        },
      ],
    },
  ];

  const header_links_influencer = [
    // {
    //   category: 'Dashboard',
    //   links: [
    //     {
    //       name: 'Dashboard',
    //       icon: <DashboardIcon />,
    //       route: '/',
    //     },
    //   ],
    // },
    {
      category: 'Aplicaciones',
      links: [
        {
          name: 'Afiliados',
          icon: <UserGroup />,
          route: '/manage-affiliates',
        },
      ],
    },
    {
      category: 'Configuración',
      links: [
        {
          name: 'Configuración',
          icon: <Configuration />,
          route: 'settings',
        },
      ],
    },
  ];

  return {
    header_links_users,
    header_links_workers,
    header_links_admins,
    header_links_influencer,
  };
};
