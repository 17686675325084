import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getBond, postBond, putBond, uploadPhoto } from '../../services/bonds';
import { Editor } from '@tinymce/tinymce-react';
import { createOrEditBonds } from './constants/createOrEditBonds';
import useForm from '../../hooks/useForm';
import getBookmarkers from '../../services/bookmarker';

import moment from 'moment';
import { toast } from 'react-toastify';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';

import { CalendarInput } from '@vilibets/vilibets-uikit/dist/atoms/CalendarInput/CalendarInput';
import { Select } from '@vilibets/vilibets-uikit/dist/atoms/Select/Select';
import { Input } from '@vilibets/vilibets-uikit/dist/atoms/Input';
import { Checkbox } from '@vilibets/vilibets-uikit/dist/atoms/Checkbox';
import { Button } from '@vilibets/vilibets-uikit/dist/atoms/Button';
import { Loading } from '@vilibets/vilibets-uikit/dist/atoms/Loading';
import { Icon } from '@vilibets/vilibets-uikit/dist/atoms/Icon';
import { ArrowLeftL } from '@vilibets/vilibets-uikit/dist/atoms/Icons';
import { SelectCheckBoxList } from '@vilibets/vilibets-uikit/dist/molecules/SelectCheckBoxList/SelectCheckBoxList';
import { getCalculators } from '../../services/calculators';

const AddEditBond = () => {
  const { initialForm, typeBonds, typeBookmarkers, levels, typeOfBond, typeRepeating } = createOrEditBonds();
  const { id } = useParams();
  const navigate = useNavigate();
  const [availableBookmarkers, setAvailableBookmarkers] = useState([]);
  const [calculators, setCalculators] = useState([]);
  const [uploadVideo, setUploadVideo] = useState(false);
  const [bond, setBond] = useState(null);
  const { values, handleInputChange } = useForm(initialForm);

  useEffect(() => {
    async function fetchFunction() {
      const response = await getBookmarkers();
      const bookmarker = [];
      response.forEach((element) => {
        bookmarker.push({
          value: element.id,
          label: <img className="h-10 dark:bg-[#f4f7fe]" src={element.logo_url} />,
        });
      });
      setAvailableBookmarkers(bookmarker);
    }
    fetchFunction();
  }, []);

  useEffect(() => {
    async function fetchData() {
      if (id) {
        const calc = await getCalculators();
        const response = await getBond(id);
        setBond({
          ...response,
          calculators: calc.map(({ id, name }) => {
            return {
              id,
              name,
              label: name,
              defaultChecked: !!response.calculators.find((calc) => calc.id === id),
              isDisabled: false,
              hasError: false,
            };
          }),
          // calculators: response.calculators.map(({ id, name }) => {
          //   return {
          //     id,
          //     name,
          //     label: name,
          //     defaultChecked: true,
          //     isDisabled: false,
          //     hasError: false,
          //   };
          // }),
        });
        handleInputChange(response);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchCalculators() {
      const calc = await getCalculators();
      setCalculators(
        calc.map(({ id, name }) => {
          return {
            id,
            name,
            label: name,
            defaultChecked: false,
            isDisabled: false,
            hasError: false,
          };
        })
      );
    }
    fetchCalculators();
  }, []);

  const handleChange = (selectName, e) => {
    handleInputChange({ [selectName]: e });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!id) {
      await toast.promise(postBond(values), {
        pending: 'Subiendo el bono...',
        success: {
          render() {
            navigate('/manage-bonds');
            return 'Bono subido correctamente!';
          },
          icon: '🟢',
        },
        error: 'Error en crear el bono 🤯',
      });
    } else {
      // Editar bono
      // atributo ficticio
      delete values.outdated;
      // Values calculators is an array of objects do a map to get only the id else if values.calculators is an array of ids do nothing
      if (values.calculators.length > 0 && values.calculators[0].id) {
        values.calculators = values.calculators.map((calc) => calc.id);
      }
      await toast.promise(putBond(values), {
        pending: 'Editando el bono...',
        success: {
          render() {
            navigate('/manage-bonds');
            return 'Bono editado correctamente!';
          },
          icon: '🟢',
        },
        error: 'Error en editar el bono 🤯',
      });
    }
  };

  const getUploadParams = ({ meta }) => {
    return { url: 'https://httpbin.org/post' };
  };

  // called every time a file's `status` changes
  const handleChangeStatus = ({ meta, file }, status) => {
    if (status === 'done') {
      setUploadVideo(false);
      handleInputChange({ video_url: file });
    } else {
      setUploadVideo(true);
    }
  };

  // add css into iframes when they are rendered delay 100ms
  setTimeout(() => {
    const iframes = document.querySelectorAll('iframe');
    // if html has dark class add black background
    if (document.querySelector('html').classList.contains('dark')) {
      iframes.forEach((iframe) => {
        // add background-color: #1a1a1a; in html inside iframe
        iframe.contentDocument.querySelector('html').style.backgroundColor = '#1a1a1a';
        iframe.contentDocument.querySelector('html').style.color = 'white';
      });
    } else {
      iframes.forEach((iframe) => {
        // add background-color: #1a1a1a; in html inside iframe
        iframe.contentDocument.querySelector('html').style.backgroundColor = 'white';
        iframe.contentDocument.querySelector('html').style.color = 'black';
      });
    }
  }, 100);

  return (id && bond && availableBookmarkers) || !id ? (
    <section>
      <div className="flex flex-row child:w-1/2 intermedium:child:w-auto justify-between items-start">
        <h2 className="font-bold text-2xl text-black-12 dark:text-accent-dark mb-5">
          {id ? 'Editar bono' : 'Crear bono'}
        </h2>
        <Button
          type="secondary"
          size="medium"
          action={() => navigate('/manage-bonds')}
          icon={
            <Icon className="stroke-white text-2xl">
              <ArrowLeftL />
            </Icon>
          }>
          Volver a la tabla
        </Button>
      </div>
      <div className="flex items-start justify-between rounded-t">
        <form onSubmit={handleSubmit} className="mb-16">
          <section className="relative px-6 py-6 flex-auto bg-white flex flex-wrap child:mb-4 mb-5 rounded-2xl shadow">
            <div className="pr-4 w-full lg:w-4/12 xl:w-6/12 xl:pr-4">
              <label className="dark:text-normal-dark" htmlFor="title">
                <span className="text-warning-red">*</span> Titulo
              </label>
              <Input
                id="title"
                width="w-full"
                placeholderMessage="Titulo"
                name="title"
                value={values.title}
                onChange={({ target }) => handleChange('title', target.value)}
                className="mt-1"
              />
            </div>
            <div className="pr-4 w-full md:w-6/12 lg:w-6/12 xl:w-6/12 xl:pr-4">
              <label className="dark:text-normal-dark" htmlFor="bookmarker_id">
                <span className="text-warning-red">*</span> Casa de apuestas
              </label>
              <Select
                options={availableBookmarkers}
                id="bookmarker_id"
                name="bookmarker_id"
                borderWidth="border-2"
                width="w-full"
                placeholderMessage="Selecciona una casa de apuestas"
                initialValue={availableBookmarkers.find((option) => option.value === values.bookmarker_id)}
                action={(e) => handleChange('bookmarker_id', e.value)}
                type="small"
                hasShadow={false}
                className="mt-1"
              />
            </div>
            <div className="w-full">
              <label>
                <span className="text-warning-red">*</span> Comentario interno Vilibets
              </label>
              <Editor
                init={{
                  menubar: false,
                  height: '150',
                  selector: 'textarea',
                  // content_css: document.documentElement.getAttribute('color-theme') === 'dark' ? 'dark' : 'light',
                }}
                value={values.comment}
                onEditorChange={(e) => {
                  handleChange('comment', e);
                }}
              />
            </div>
            <div className="w-full">
              <label>
                <span className="text-warning-red">*</span> Descripción
              </label>
              <Editor
                init={{
                  menubar: false,
                }}
                value={values.description}
                onEditorChange={(e) => {
                  handleChange('description', e);
                }}
              />
            </div>
            <div className="pr-4 w-full md:w-6/12 lg:w-4/12 xl:w-3/12 xl:pr-4">
              <label className="dark:text-normal-dark" htmlFor="start_at">
                Bono inicio
              </label>
              <CalendarInput
                inputClass={'h-9 border-black shadow-none mt-1.5 dark:bg-white'}
                iconClass="top-[33%]"
                time
                initialDate={values.start_at ? new Date(values.start_at) : null}
                action={(e) => handleChange('start_at', moment(e).format('YYYY/MM/DD HH:mm:ss'))}
              />
            </div>
            <div className="pr-4 w-full md:w-6/12 lg:w-4/12 xl:w-3/12 xl:pr-4">
              <label className="dark:text-normal-dark" htmlFor="valid_until">
                Bono válido hasta
              </label>
              <CalendarInput
                inputClass={'h-9 border-black shadow-none mt-1.5 dark:bg-white'}
                iconClass="top-[33%]"
                time
                initialDate={values.valid_until ? new Date(values.valid_until) : null}
                minDate={
                  values.start_at !== null && values.start_at.length > 0 ? new Date(values.start_at) : new Date()
                }
                action={(e) => handleChange('valid_until', moment(e).format('YYYY/MM/DD HH:mm:ss'))}
              />
            </div>
            <div className="pr-4 w-full md:w-6/12 lg:w-4/12 xl:w-3/12 xl:pr-4">
              <label className="dark:text-normal-dark" htmlFor="min_reward">
                Ganancias mínimas
              </label>
              <Input
                id="min_reward"
                width="w-full"
                type={'number'}
                placeholderMessage="Ganancias mínimas"
                name="min_reward"
                value={values.min_reward}
                onChange={({ target }) => handleChange('min_reward', target.value)}
                className="mt-1"
              />
            </div>
            <div className="pr-4 w-full md:w-6/12 lg:w-4/12 xl:w-3/12 xl:pr-4">
              <label className="dark:text-normal-dark" htmlFor="max_reward">
                Ganancias máximas
              </label>
              <Input
                id="max_reward"
                width="w-full"
                type={'number'}
                placeholderMessage="Ganancias máximas"
                name="max_reward"
                value={values.max_reward}
                onChange={({ target }) => handleChange('max_reward', target.value)}
                className="mt-1"
              />
            </div>
          </section>
          <section className="relative px-6 py-6 flex-auto bg-white flex flex-wrap child:mb-4 mb-5 rounded-2xl shadow">
            <div className="pr-4 w-full md:w-6/12 lg:w-4/12 xl:w-3/12 xl:pr-4">
              <label className="dark:text-normal-dark" htmlFor="match">
                Partido
              </label>
              <Input
                id="match"
                width="w-full"
                placeholderMessage="Partido"
                name="match"
                value={values.match}
                onChange={({ target }) => handleChange('match', target.value)}
                className="mt-1"
              />
            </div>
            <div className="pr-4 w-full md:w-6/12 lg:w-4/12 xl:w-3/12 xl:pr-4">
              <label className="dark:text-normal-dark" htmlFor="min_bet">
                Cuota mínima
              </label>
              <Input
                id="min_bet"
                width="w-full"
                type={'number'}
                placeholderMessage="Cuota mínima"
                name="min_bet"
                value={values.min_bet}
                onChange={({ target }) => handleChange('min_bet', target.value)}
                className="mt-1"
              />
            </div>
            <div className="pr-4 w-full md:w-6/12 lg:w-4/12 xl:w-3/12 xl:pr-4">
              <label className="dark:text-normal-dark" htmlFor="valid_market">
                Mercado válido
              </label>
              <Input
                id="valid_market"
                width="w-full"
                placeholderMessage="mercado válido"
                name="valid_market"
                value={values.valid_market}
                onChange={({ target }) => handleChange('valid_market', target.value)}
                className="mt-1"
              />
            </div>
            <div className="pr-4 w-full md:w-6/12 lg:w-4/12 xl:w-3/12 xl:pr-4">
              <label className="dark:text-normal-dark" htmlFor="max_bet">
                Apuesta máxima
              </label>
              <Input
                id="max_bet"
                width="w-full"
                placeholderMessage="Apuesta máxima"
                name="max_bet"
                value={values.max_bet}
                onChange={({ target }) => handleChange('max_bet', target.value)}
                className="mt-1"
              />
            </div>
            <div className="pr-4 w-full md:w-6/12 lg:w-4/12 xl:w-3/12 xl:pr-4">
              <label className="dark:text-normal-dark" htmlFor="reward">
                Recompensa
              </label>
              <Input
                id="reward"
                width="w-full"
                placeholderMessage="Recompensa"
                name="reward"
                value={values.reward}
                onChange={({ target }) => handleChange('reward', target.value)}
                className="mt-1"
              />
            </div>
            <div className="pr-4 w-full lg:w-4/12 xl:w-6/12 xl:pr-4">
              <label className="dark:text-normal-dark" htmlFor="condition">
                <span className="text-warning-red">*</span> Condición
              </label>
              <Input
                id="condition"
                width="w-full"
                placeholderMessage="Condición"
                name="condition"
                value={values.condition}
                onChange={({ target }) => handleChange('condition', target.value)}
                className="mt-1"
              />
            </div>
            <div className="pr-4 w-full md:w-6/12 lg:w-4/12 xl:w-3/12 xl:pr-4">
              <label className="dark:text-normal-dark" htmlFor="calculators">
                Calculadoras
              </label>
              <div className="mt-1">
                <SelectCheckBoxList
                  action={(item) => {
                    const itemsChecked = item.filter((item) => item.defaultChecked).map((item) => item.id);
                    handleChange('calculators', itemsChecked);
                  }}
                  labelAllNone="Todo / Nada"
                  width={'!w-full'}
                  placeHolderMessageAllSelected="Mostrando todas las calculadoras"
                  items={bond?.calculators ? bond?.calculators : calculators}
                  hasShadow={false}
                  border
                  type="small"
                />
              </div>
            </div>
          </section>
          <section className="relative px-6 py-6 flex-auto bg-white flex flex-wrap child:mb-4 mb-5 rounded-2xl shadow">
            <div className="pr-4 w-full md:w-6/12 lg:w-4/12 xl:w-3/12 xl:pr-4">
              <label className="dark:text-normal-dark" htmlFor="type">
                <span className="text-warning-red">*</span> Tipo
              </label>
              <Select
                options={typeBonds}
                id="type"
                name="type"
                borderWidth="border-2"
                width="w-full"
                placeholderMessage="Tipo"
                action={(e) => handleChange('type', e.value)}
                initialValue={typeBonds.find((option) => option.value === values.type)}
                type="small"
                hasShadow={false}
                className="mt-1"
              />
            </div>
            <div className="pr-4 w-full md:w-6/12 lg:w-4/12 xl:w-3/12 xl:pr-4">
              <label className="dark:text-normal-dark" htmlFor="type_of_bookmarker">
                <span className="text-warning-red">*</span> Tipo de casa de apuesta
              </label>
              <Select
                options={typeBookmarkers}
                id="type_of_bookmarker"
                name="type_of_bookmarker"
                borderWidth="border-2"
                width="w-full"
                placeholderMessage="Tipo de casa de apuesta"
                action={(e) => handleChange('type_of_bookmarker', e.value)}
                initialValue={typeBookmarkers.find((option) => option.value === values.type_of_bookmarker)}
                type="small"
                hasShadow={false}
                className="mt-1"
              />
            </div>
            <div className="pr-4 w-full md:w-6/12 lg:w-4/12 xl:w-3/12 xl:pr-4">
              <label className="dark:text-normal-dark" htmlFor="type_of_bond">
                <span className="text-warning-red">*</span> Tipo de bono
              </label>
              <Select
                options={typeOfBond}
                id="type_of_bond"
                name="type_of_bond"
                borderWidth="border-2"
                width="w-full"
                placeholderMessage="Tipo de bono"
                initialValue={typeOfBond.find((option) => option.value === values.type_of_bond)}
                action={(e) => handleChange('type_of_bond', e.value)}
                type="small"
                hasShadow={false}
                className="mt-1"
              />
            </div>
            <div className="pr-4 w-full md:w-6/12 lg:w-4/12 xl:w-3/12 xl:pr-4">
              <label className="dark:text-normal-dark" htmlFor="level">
                <span className="text-warning-red">*</span> Nivel de apuesta
              </label>
              <Select
                options={levels}
                id="level"
                name="level"
                borderWidth="border-2"
                width="w-full"
                placeholderMessage="Nivel de apuesta"
                initialValue={levels.find((option) => option.value === values.level)}
                action={(e) => handleChange('level', e.value)}
                type="small"
                hasShadow={false}
                className="mt-1"
              />
            </div>
            <div className="pr-4 w-full md:w-6/12 lg:w-4/12 xl:w-3/12 xl:pr-4">
              <label className="dark:text-normal-dark" htmlFor="code">
                Código
              </label>
              <Input
                id="code"
                width="w-full"
                placeholderMessage="Código"
                name="code"
                value={values.code}
                onChange={({ target }) => handleChange('code', target.value)}
                className="mt-1"
              />
            </div>
            <div className="pr-4 w-full md:w-6/12 lg:w-4/12 xl:w-3/12 xl:pr-4">
              <label className="dark:text-normal-dark" htmlFor="url">
                Url
              </label>
              <Input
                id="url"
                width="w-full"
                placeholderMessage="Url"
                name="url"
                value={values.url}
                onChange={({ target }) => handleChange('url', target.value)}
                className="mt-1"
              />
            </div>

            <div className="pr-4 w-full md:w-6/12 lg:w-4/12 xl:w-3/12 xl:pr-4">
              <label className="dark:text-normal-dark" htmlFor="type_repeats_bond">
                Repetir bono
              </label>
              <Select
                options={typeRepeating}
                id="type_repeats_bond"
                name="type_repeats_bond"
                borderWidth="border-2"
                width="w-full"
                placeholderMessage="Repetir bono"
                action={(e) => handleChange('type_repeats_bond', e.value)}
                initialValue={
                  typeRepeating.find((option) => option.value === values.type_repeats_bond) || {
                    value: '',
                    label: 'No repetir',
                  }
                }
                type="small"
                hasShadow={false}
                className="mt-1"
              />
            </div>
            {values.type_repeats_bond !== '' && values.type_repeats_bond !== null && (
              <div className="pr-4 w-full md:w-6/12 lg:w-4/12 xl:w-3/12 xl:pr-4">
                <label className="dark:text-normal-dark" htmlFor="finish_repeats_bond">
                  Se repite hasta
                </label>
                <CalendarInput
                  inputClass={'h-9 border-black shadow-none mt-1.5 dark:bg-white'}
                  iconClass="top-[33%]"
                  time
                  initialDate={values.finish_repeats_bond ? new Date(values.finish_repeats_bond) : null}
                  minDate={new Date()}
                  action={(e) => handleChange('finish_repeats_bond', moment(e).format('YYYY/MM/DD HH:mm:ss'))}
                />
              </div>
            )}
            <div className="flex w-full flex-wrap">
              <div className="flex pr-4 w-full md:w-6/12 lg:w-4/12 xl:w-2/12 xl:pr-4">
                <Checkbox
                  defaultChecked={values.golden_goal}
                  name={'golden_goal'}
                  action={(e, a) => handleChange(e, a)}>
                  Gol de oro
                </Checkbox>
              </div>
              <div className="flex pr-4 w-full md:w-6/12 lg:w-4/12 xl:w-2/12 xl:pr-4">
                <Checkbox
                  defaultChecked={values.send_it_telegram}
                  name={'send_it_telegram'}
                  action={(e, a) => handleChange(e, a)}>
                  Enviar telegram
                </Checkbox>
              </div>
              <div className="flex pr-4 w-full md:w-6/12 lg:w-4/12 xl:w-3/12 xl:pr-4">
                <Checkbox
                  defaultChecked={values.need_invitation}
                  name={'need_invitation'}
                  action={(e, a) => handleChange(e, a)}>
                  Necesita invitación
                </Checkbox>
              </div>
              <div className="flex pr-4 w-full md:w-6/12 lg:w-4/12 xl:w-3/12 xl:pr-4">
                <Checkbox defaultChecked={values.risk} name={'risk'} action={(e, a) => handleChange(e, a)}>
                  Promoción con riesgo
                </Checkbox>
              </div>
              <div className="flex pr-4 w-full md:w-6/12 lg:w-4/12 xl:w-2/12 xl:pr-4">
                <Checkbox defaultChecked={values.live} name={'live'} action={(e, a) => handleChange(e, a)}>
                  En directo
                </Checkbox>
              </div>
            </div>
            <div className="flex w-full flex-col">
              <label className="mb-1 dark:text-normal-dark" htmlFor="condition">
                Vídeo
              </label>
              <Dropzone
                getUploadParams={getUploadParams}
                maxFiles={1}
                multiple={false}
                onChangeStatus={handleChangeStatus}
                inputContent="Subir vídeo"
                accept="audio/*,video/*"
              />
            </div>
            {typeof values.video_url === 'string' && values.video_url.length > 0 && (
              <div className="flex w-full flex-col items-center">
                <video src={values.video_url} width="350" height="300" type="video/mp4" controls />
              </div>
            )}

            <div className="w-full">
              <label>
                <span className="text-warning-red">*</span> Contenido
              </label>
              <Editor
                init={{
                  height: '1000',
                  plugins:
                    'preview importcss searchreplace autolink autosave save directionality visualblocks visualchars fullscreen link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists charmap quickbars emoticons',
                  menubar: 'file edit view insert format tools table tc help',
                  toolbar:
                    'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment',
                  // tinymce 6
                  images_upload_handler: async (blobInfo) => {
                    return new Promise((resolve, reject) => {
                      // axios
                      uploadPhoto(blobInfo)
                        .then((res) => {
                          resolve(res.location);
                        })
                        .catch((err) => {
                          reject(err);
                        });
                    });
                  },
                }}
                value={values.content}
                onEditorChange={(e) => {
                  handleChange('content', e);
                }}
              />
            </div>
          </section>

          <Button
            size="large"
            typeButton="submit"
            disabled={
              !(
                values.comment &&
                values.description &&
                values.content &&
                values.bookmarker_id &&
                values.type &&
                values.level &&
                values.type_of_bond &&
                values.type_of_bookmarker &&
                values.title &&
                values.condition &&
                !uploadVideo
              )
            }>
            {id ? 'Editar bono' : 'Crear nuevo bono'}
          </Button>
        </form>
      </div>
    </section>
  ) : (
    <Loading />
  );
};

export default AddEditBond;
