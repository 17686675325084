import { Button } from '@vilibets/vilibets-uikit/dist/atoms/Button';
import { Icon } from '@vilibets/vilibets-uikit/dist/atoms/Icon';
import {
  ArrowRightL,
  EditText,
  ExternalLink,
  Trash,
  UserGroup,
  IconUser,
  File,
  IconWallet,
  ArrowLeftL,
} from '@vilibets/vilibets-uikit/dist/atoms/Icons';
import SubscriptionEuro from '@vilibets/vilibets-uikit/dist/atoms/Icons/SubscriptionEuro';
import LogoVilibets from '@vilibets/vilibets-uikit/dist/atoms/Icons/LogoVilibets';
import { Input } from '@vilibets/vilibets-uikit/dist/atoms/Input';
import { Switcher } from '@vilibets/vilibets-uikit/dist/atoms/Switch';
import { Table } from '@vilibets/vilibets-uikit/dist/atoms/Table/Table';
import { IconButton } from '@mui/material';
import moment from 'moment';
import { useEffect, useState, useRef } from 'react';
import { NavLink, useSearchParams, useNavigate } from 'react-router-dom';
import PopupDelete from '../../components/PopupDelete/PopupDelete';
import updateUser, {
  deleteUser,
  getAllNonManagementUsers,
  getNonManagementUsers,
  exportToCsv,
} from '../../services/users';
import getPaymentMethods from '../../services/paymentMethods';
import CreditUserPopup from './CreditUserPopup';
import UserSubscriptionDetailPopup from './UserSubscriptionDetailPopup';
import UserTestPopup from './UserTestPopup';
import { Select } from '@vilibets/vilibets-uikit/dist/atoms/Select/Select';
import { toast } from 'react-toastify';

const Users = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [users, setUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [displayDeletePopup, setDisplayDeletePopup] = useState(false);
  const [deleteItem, setDeleteItem] = useState(false);
  const [displayCreditPopup, setDisplayCreditPopup] = useState(false);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [editableRowData, setEditableRowData] = useState(null);
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [displaySubscriptionDetailPopup, setDisplaySubscriptionDetailPopup] = useState(false);
  const [displayUserTestPopup, setDisplayUserTestPopup] = useState(false);
  const [usersPages, setUsersPages] = useState();
  const [paymentMethods, setPaymentMethods] = useState();
  const [title, setTitle] = useState('');
  const navigate = useNavigate();
  // const [telegramData, setTelegramData] = useState(null);
  // const [displayTelegramDetailPopup, setDisplayTelegramDetailPopup] = useState(false);
  const [time, setTime] = useState('');
  const timerRef = useRef(null);

  const optionsRoles = [
    { value: 'admin', label: 'Administrador' },
    { value: 'influencer', label: 'Influencer' },
    { value: 'telegram', label: 'Telegram' },
    { value: 'worker', label: 'Trabajador' },
    { value: 'user', label: 'Usuario' },
  ];
  const optionsUserType = [
    { value: null, label: 'Todos los usuarios' },
    { value: 'false', label: 'Usuarios reales' },
    { value: 'true', label: 'Usuarios test' },
  ];
  const optionsWithSubscription = [
    { value: null, label: 'Todos los usuarios' },
    { value: 1, label: 'Con suscripción' },
    { value: 0, label: 'Sin suscripción' },
  ];
  const optionsExpireSubscription = [
    { value: null, label: 'Todos los usuarios' },
    { value: -30, label: 'Desde hace 1 mes' },
    { value: -20, label: 'Desde hace 20 días' },
    { value: -10, label: 'Desde hace 10 días' },
    { value: -5, label: 'Desde hace 5 días' },
    { value: 0, label: 'Hoy' },
    { value: 1, label: 'Hoy o mañana' },
    { value: 5, label: 'Próximos 5 días' },
    { value: 10, label: 'Próximos 10 días' },
  ];

  function changeOrder(column, sortedSide) {
    searchParams.set('orderby', column);
    searchParams.set('orderby_direction', sortedSide);
    searchParams.set('page_number', 1);
    setSearchParams(searchParams);
  }

  function changePageNumber(page) {
    searchParams.set('page_number', page);
    setSearchParams(searchParams);
  }

  const handleIsTestChange = (element, checked) => {
    const user = {
      id: element.id,
      is_test: checked,
    };

    async function updateTestUser(user) {
      const response = await updateUser(user);
      element.is_test = response.is_test;
      // Abre el popup para actualizar las observaciones
      setSubscriptionData(element);
      setDisplayUserTestPopup(true);
    }

    updateTestUser(user);
  };

  const handleRolChange = (e) => {
    e.value === null || e.value === undefined ? searchParams.delete('role') : searchParams.set('role', e.value);
    searchParams.set('page_number', 1);
    setSearchParams(searchParams);
  };

  const handleUserType = (e) => {
    e.value === null || e.value === undefined ? searchParams.delete('is_test') : searchParams.set('is_test', e.value);
    searchParams.set('page_number', 1);
    setSearchParams(searchParams);
  };

  const handleSubscriptionActive = (e) => {
    e.value === null || e.value === undefined
      ? searchParams.delete('subscription_active')
      : searchParams.set('subscription_active', e.value);
    searchParams.set('page_number', 1);
    setSearchParams(searchParams);
  };

  const handleDaysToExpire = (e) => {
    e.value === null || e.value === undefined
      ? searchParams.delete('days_to_expired')
      : searchParams.set('days_to_expired', e.value);
    searchParams.set('page_number', 1);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    async function fetchData() {
      const allUsers = await getAllNonManagementUsers();
      setAllUsers(allUsers);

      // Get payment methods
      const responseMethods = await getPaymentMethods();
      setPaymentMethods(responseMethods);
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      setUsers([]);
      setLoadingUsers(true);
      await toast
        .promise(getNonManagementUsers(window.location.search), {
          pending: 'Obteniendo datos...',
          error: 'Error en la consulta 🤯',
        })
        .then((response) => {
          setUsersPages({
            current_page: response.current_page,
            last_page: response.last_page,
            total: response.total,
            per_page: response.per_page,
          });
          setUsers(response.data);
          setLoadingUsers(false);
        });
    }

    // Título de la página
    setTitle(getDescriptionTitle());

    // Datos
    fetchData();
  }, [searchParams]);

  function getDescriptionTitle() {
    let description = '';
    if (searchParams.get('type') === 'new_subscriptions') {
      description =
        'Nuevos usuarios que se han suscrito (' +
        moment(searchParams.get('date_from')).format('DD/MM/YYYY') +
        '-' +
        moment(searchParams.get('date_to')).format('DD/MM/YYYY') +
        ')';
    } else if (searchParams.get('type') === 'expired_users') {
      description =
        'Usuarios que no han renovado (' +
        moment(searchParams.get('date_from')).format('DD/MM/YYYY') +
        '-' +
        moment(searchParams.get('date_to')).format('DD/MM/YYYY') +
        ')';
    } else if (searchParams.get('type') === 'all_subscriptions') {
      description =
        'Usuarios que se han suscrito (' +
        moment(searchParams.get('date_from')).format('DD/MM/YYYY') +
        '-' +
        moment(searchParams.get('date_to')).format('DD/MM/YYYY') +
        ')';
    } else {
      description = 'Usuarios';
    }
    return description;
  }

  useEffect(() => {
    if (time) {
      // Clear the interval when the component unmounts
      if (time.length === 0) {
        clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => setSearchParams(searchParams), 500);
      } else {
        timerRef.current = setTimeout(() => setSearchParams(searchParams), 500);
      }

      return () => clearTimeout(timerRef.current);
    }
  }, [time]);

  return (
    users && (
      <>
        <div className="flex intermedium:child:w-auto flex-col-reverse md:justify-between md:flex-row">
          <h1 className="font-bold text-2xl text-black-12 dark:text-accent-dark">{title}</h1>
          {searchParams.get('type') && (
            <Button
              type="secondary"
              size="medium"
              className="w-auto mb-2 md:mb-0"
              action={() => navigate(-1)}
              icon={
                <Icon className="stroke-white text-2xl">
                  <ArrowLeftL />
                </Icon>
              }>
              Volver
            </Button>
          )}
        </div>
        <section className="flex flex-col w-full lg:mb-0 lg:child:w-auto lg:flex-row justify-between pb-4">
          <section className="flex gap-2 lg:gap-0 flex-col w-full child:mb-2">
            <section className="flex child:lg:mr-2 gap-2">
              <div className="w-full">
                <label className="dark:text-normal-dark">Buscar usuario</label>
                <Input
                  type="text"
                  value={searchParams.get('search')}
                  placeholder="Buscar usuario"
                  title="Rol de los usuarios"
                  width="auto"
                  className="child:h-[48px] lg:mr-2"
                  onChange={({ target }) => {
                    searchParams.set('search', target.value);
                    searchParams.set('page_number', 1);
                    setTime(target.value);
                  }}
                />
              </div>
              {searchParams.get('type') && (
                <div>
                  <label className="dark:text-normal-dark">&nbsp;</label>
                  <Button
                    className="mr-2 h-[48px]"
                    type="grey"
                    outline={true}
                    size="medium"
                    action={async () => {
                      await toast.promise(exportToCsv(window.location.search), {
                        pending: 'Exportando lista de usuarios... (puede tardar unos segundos)',
                        success: {
                          render() {
                            return 'Exportación finalizada!';
                          },
                          // other options
                          icon: '🟢',
                        },
                        error: 'Error en la exportación 🤯',
                      });
                    }}
                    icon={
                      <Icon>
                        <File />
                      </Icon>
                    }>
                    Exportar
                  </Button>
                </div>
              )}
            </section>
            {!searchParams.get('type') && (
              <section className="flex gap-2 lg:gap-0 flex-col lg:flex-row child:lg:mr-2">
                <section className="flex gap-2 lg:gap-0 flex-row child:lg:mr-2 lg:child:w-auto child:w-1/2">
                  <div>
                    <label className="dark:text-normal-dark">Rol</label>
                    <Select
                      id="role"
                      border={true}
                      width="w-auto lg:w-52"
                      placeholderMessage="Rol"
                      initialValue={optionsRoles.find((option) => option.value === searchParams.get('role'))}
                      action={handleRolChange}
                      options={optionsRoles}
                      icon={<UserGroup />}
                    />
                  </div>
                  <div>
                    <label className="dark:text-normal-dark">Tipo (test/real)</label>
                    <Select
                      id="usertype"
                      border={true}
                      width="w-auto lg:w-52"
                      placeholderMessage="Todos los usuarios"
                      initialValue={optionsUserType.find((option) => option.value === searchParams.get('is_test'))}
                      action={handleUserType}
                      options={optionsUserType}
                      icon={<IconUser />}
                    />
                  </div>
                </section>
                <section className="flex gap-2 lg:gap-0 flex-row child:lg:mr-2 lg:child:w-auto child:w-1/2">
                  <div>
                    <label className="dark:text-normal-dark">Suscripción activa</label>
                    <Select
                      id="activesubscription"
                      border={true}
                      width="w-auto lg:w-52"
                      placeholderMessage="Todos los usuarios"
                      initialValue={optionsWithSubscription.find(
                        (option) => option.value === parseInt(searchParams.get('subscription_active'))
                      )}
                      action={handleSubscriptionActive}
                      options={optionsWithSubscription}
                      icon={<SubscriptionEuro />}
                    />
                  </div>
                  <div>
                    <label className="dark:text-normal-dark">Fin suscripción</label>
                    <Select
                      id="expiresubscription"
                      border={true}
                      width="w-auto lg:w-52"
                      placeholderMessage="Todos los usuarios"
                      initialValue={optionsExpireSubscription.find(
                        (option) => option.value === parseInt(searchParams.get('days_to_expired'))
                      )}
                      action={handleDaysToExpire}
                      options={optionsExpireSubscription}
                      icon={<IconWallet />}
                    />
                  </div>
                </section>
              </section>
            )}
          </section>
          {!searchParams.get('type') && (
            <section className="flex flex-row lg:child:w-auto items-end child:mb-2 child:w-1/2">
              <Button
                className="mr-2 h-[48px]"
                type="grey"
                outline={true}
                size="medium"
                action={async () => {
                  await toast.promise(exportToCsv(window.location.search), {
                    pending: 'Exportando lista de usuarios... (puede tardar unos segundos)',
                    success: {
                      render() {
                        return 'Exportación finalizada!';
                      },
                      // other options
                      icon: '🟢',
                    },
                    error: 'Error en la exportación 🤯',
                  });
                }}
                icon={
                  <Icon>
                    <File />
                  </Icon>
                }>
                Exportar
              </Button>
              <Button
                className="h-[48px]"
                type="secondary"
                size="small"
                action={() => {
                  setEditableRowData(null);
                  setDisplayCreditPopup(true);
                }}
                icon={
                  <Icon className="stroke-white text-2xl">
                    <UserGroup />
                  </Icon>
                }>
                Crear usuario
              </Button>
            </section>
          )}
        </section>

        <Table
          tableClass="w-full hidden lg:table"
          emptyMessage={loadingUsers ? 'Cargando usuarios...' : 'No hay datos para mostrar'}
          changeOrder={(e, c) => changeOrder(e, c)}
          rowsData={prepareTableData()}
          colsData={[
            {
              Header: 'Nombre de usuario',
              accessor: 'username',
              sortable: true,
            },
            {
              Header: 'Email',
              accessor: 'email',
              sortable: true,
            },
            // {
            //   Header: 'Telegram',
            //   accessor: 'telegram',
            // },
            {
              Header: 'Miembro desde',
              accessor: 'created_at',
              sortable: true,
            },
            {
              Header: 'Bonos completados',
              accessor: 'completed_bonds_counter',
              align: 'center',
              cellColor: 'primary-1 dark:bg-white',
            },
            {
              Header: 'Método pago',
              accessor: 'payment_method',
            },
            {
              Header: 'Test',
              accessor: 'is_test',
              align: 'center',
            },
            {
              Header: 'Observaciones',
              accessor: 'comment',
            },
            {
              Header: 'Suscripción',
              accessor: 'subscription',
              align: 'center',
            },
            {
              Header: 'Tarifa',
              accessor: 'rate',
            },
            {
              Header: 'Fin suscripción',
              accessor: 'end_subscription',
              sortable: false,
            },
            {
              Header: 'Acciones',
              accessor: 'actions',
            },
          ]}
        />
        <section className="lg:hidden">
          {users.map((element, index) => (
            <div className="flex flex-col justify-between mb-5 bg-white shadow rounded-xl p-4" key={index}>
              <div className="flex flex-col text-center mb-3">
                <p className="text-black-12 dark:text-accent-dark font-bold">{element.name + ' ' + element.surnames}</p>
                <p className="text-black-10 text-sm ">{element.username}</p>
              </div>
              <div className="flex flex-col child:mb-3">
                <section>
                  <span className="text-black-10 dark:text-black-12 text-sm bg-black-3 px-1.5 py-0 rounded">Email</span>
                  <p className="text-black-12 dark:text-accent-dark font-bold">{element.email}</p>
                </section>
                {/* <section>
                  <span className="text-black-10 text-sm bg-black-3 px-1.5 py-0 rounded">Telegram</span>
                  <div className="text-center p-2 mt-0.5 rounded-b-sm flex">
                    {
                      <Tooltip
                        title={
                          element.telegram_info == null
                            ? 'Id de telegram no válido'
                            : element.telegram_info.status === 'member'
                            ? 'En el grupo'
                            : element.telegram_info.status === 'kicked'
                            ? 'Expulsado'
                            : 'Ha dejado el grupo'
                        }>
                        <IconButton
                          onClick={() => {
                            setTelegramData(element);
                            setDisplayTelegramDetailPopup(true);
                          }}
                          className={`${
                            element.telegram_info == null
                              ? 'stroke-black'
                              : (element.telegram_info.status === 'member'
                                  ? 'stroke-secondary font-bold'
                                  : element.telegram_info.status === 'kicked'
                                  ? 'stroke-warning-red font-bold'
                                  : 'stroke-warning-orange') + ' font-bold'
                          } text-xl cursor-pointer child:mx-auto`}>
                          <IconTelegram />
                        </IconButton>
                      </Tooltip>
                    }
                  </div>
                </section> */}
                <section>
                  <span className="text-black-10 dark:text-black-12 text-sm bg-black-3 px-1.5 py-0 rounded">
                    Miembro desde
                  </span>
                  <p className="text-black-12 dark:text-accent-dark font-bold break-words">
                    {moment(element.created_at).fromNow()}
                  </p>
                </section>
                <section>
                  <span className="text-black-10 dark:text-black-12 text-sm bg-black-3 px-1.5 py-0 rounded">Test</span>
                  <p className="text-black-12 dark:text-accent-dark font-bold break-words">
                    <Switcher
                      id={element.id}
                      defaultValue={element.is_test}
                      disabled={!element.subscription && !element.is_test}
                      action={(checked) => handleIsTestChange(element, checked)}
                    />
                  </p>
                </section>
                {element.comment && (
                  <section>
                    <span className="text-black-10 dark:text-black-12 text-sm bg-black-3 px-1.5 py-0 rounded">
                      Observaciones
                    </span>
                    <p className="text-black-12 dark:text-accent-dark font-bold break-words">{element.comment}</p>
                  </section>
                )}
                <section className="flex flex-row gap-2 justify-center">
                  <section className="flex flex-col">
                    <div className="text-center dark:text-black-12 bg-black-2 p-3.5 rounded-t-lg">
                      Bonos completados
                    </div>
                    <div className="bg-primary-2 dark:text-accent-dark text-center p-2 mt-0.5 rounded-b-sm">
                      {element.completed_bonds_counter}
                    </div>
                  </section>
                  <section className="flex flex-col">
                    <div className="text-center bg-black-2 dark:text-black-12 p-3.5 rounded-t-lg">
                      Gestionar suscripción
                    </div>
                    <section className="flex flex-row gap-2">
                      <div className="bg-primary-2 dark:text-accent-dark text-center p-2 mt-0.5 rounded-b-sm">
                        {
                          <Icon
                            onClick={() => {
                              setSubscriptionData(element);
                              if (!element.is_test) {
                                setDisplaySubscriptionDetailPopup(true);
                              }
                            }}
                            className={`${
                              element.subscription && !element.is_test ? 'stroke-secondary font-bold' : 'stroke-black'
                            } text-2xl cursor-pointer child:mx-auto`}>
                            <SubscriptionEuro />
                          </Icon>
                        }
                      </div>
                      <div
                        className="bg-primary-2 dark:text-accent-dark text-center p-2 mt-0.5 rounded-b-sm"
                        title="Fecha fin suscripción">
                        {element.subscription?.end_date
                          ? moment(element.subscription?.end_date).format('DD/MM/YYYY HH:mm:ss')
                          : ''}
                      </div>
                    </section>
                  </section>
                </section>
              </div>
              <div className="flex flex-row mx-auto">{generateActionButtons(element)}</div>
            </div>
          ))}
        </section>
        <section id="paginator" className="mt-5 flex flex-row justify-between mb-20">
          {usersPages && (
            <>
              <section id="numbers">
                {generatePaginatorRange(usersPages).map((element) =>
                  usersPages.current_page === element ? (
                    <button className="px-5 py-3 bg-primary rounded-xl text-white font-bold shadow-inn3r-shadow dark:shadow-none mr-2">
                      {element}
                    </button>
                  ) : (
                    <button
                      onClick={() => changePageNumber(element)}
                      className="px-5 py-3 bg-white rounded-xl text-black-10 font-bold shadow mr-2">
                      {element}
                    </button>
                  )
                )}
              </section>
              {usersPages.current_page < usersPages.last_page && (
                <button
                  onClick={() => changePageNumber(usersPages.current_page + 1)}
                  className="px-5 py-3 bg-white rounded-xl text-black-12 font-bold shadow flex flex-row items-center dark:text-accent-dark">
                  Siguiente{' '}
                  <Icon className="text-xl stroke-black pl-2">
                    <ArrowRightL />
                  </Icon>
                </button>
              )}
            </>
          )}
        </section>
        <CreditUserPopup
          display={displayCreditPopup}
          closePopup={async () => {
            setDisplayCreditPopup(!displayCreditPopup);
            setEditableRowData(null);
            const response = await getNonManagementUsers(window.location.search);
            setUsersPages({
              current_page: response.current_page,
              last_page: response.last_page,
              total: response.total,
              per_page: response.per_page,
            });
            setUsers(response.data);
          }}
          data={editableRowData}
          users={allUsers}
        />
        <PopupDelete
          display={displayDeletePopup}
          item={deleteItem}
          closePopup={() => {
            setDisplayDeletePopup(!displayDeletePopup);
          }}
          title={deleteItem.name}
          action_cancel={() => {
            setDisplayDeletePopup(false);
          }}
          action_delete={async () => {
            await deleteUser(deleteItem.id);
            setDisplayDeletePopup(false);
            const response = await getNonManagementUsers(window.location.search);
            setUsersPages({
              current_page: response.current_page,
              last_page: response.last_page,
              total: response.total,
              per_page: response.per_page,
            });
            setUsers(response.data);
          }}
        />
        <UserSubscriptionDetailPopup
          display={displaySubscriptionDetailPopup}
          closePopup={async () => {
            setDisplaySubscriptionDetailPopup(!displaySubscriptionDetailPopup);
            refresh();
          }}
          user={subscriptionData}
        />
        <UserTestPopup
          display={displayUserTestPopup}
          closePopup={async () => {
            setDisplayUserTestPopup(!displayUserTestPopup);
          }}
          user={subscriptionData}
        />
        {/* <TelegramDetailPopup
          display={displayTelegramDetailPopup}
          closePopup={async () => {
            setDisplayTelegramDetailPopup(!displayTelegramDetailPopup);
            refresh();
          }}
          user={telegramData}
        /> */}
      </>
    )
  );

  async function refresh() {
    const response = await getNonManagementUsers(window.location.search);
    setUsersPages({
      current_page: response.current_page,
      last_page: response.last_page,
      total: response.total,
      per_page: response.per_page,
    });
    setUsers(response.data);
  }

  function generatePaginatorRange(usersPages) {
    const pages = [];
    if (usersPages) {
      if (usersPages.total > 0) {
        if (usersPages.per_page < usersPages.total) {
          if (usersPages.current_page > 1) {
            for (
              let i = usersPages.current_page - 1;
              i <= usersPages.last_page && i < usersPages.current_page + 3;
              i++
            ) {
              pages.push(i);
            }
          } else {
            for (let i = usersPages.current_page; i <= usersPages.last_page && i < usersPages.current_page + 3; i++) {
              pages.push(i);
            }
          }
        }
      }
    }
    return pages;
  }

  function paymentMethod(pUser) {
    const payment_method =
      paymentMethods &&
      paymentMethods.find((option) => option.id === pUser?.subscription?.last_order?.payment_method_id);

    return payment_method?.payment_name === 'affiliates' ? (
      <LogoVilibets height="30px" width="50px" className={'w-15'} />
    ) : payment_method?.payment_name === 'paypal' ? (
      <span>{pUser?.subscription?.paypal_id}</span>
    ) : payment_method?.payment_name === 'others' ? (
      <span>Otros</span>
    ) : payment_method?.payment_name ? (
      <img
        height="75px"
        width="75px"
        src={
          payment_method?.payment_name === 'stripe'
            ? './stripe_logo.png'
            : payment_method?.payment_name === 'transfer'
            ? './transfer_logo.png'
            : ''
        }
      />
    ) : (
      ''
    );
  }

  function prepareTableData() {
    if (users) {
      const rowsData = [];
      users.forEach((element) => {
        rowsData.push({
          username: element.username,
          email: element.email,
          created_at: moment(element.created_at).fromNow(),
          completed_bonds_counter: <span className="text-primary">{element.completed_bonds_counter}</span>,
          payment_method: element?.subscription && paymentMethod(element),
          is_test: (
            <Switcher
              id={element.id}
              defaultValue={element.is_test}
              disabled={!element.subscription && !element.is_test}
              action={(checked) => handleIsTestChange(element, checked)}
            />
            // <input type={'checkbox'} data-update={element.id} checked={element.is_test} onChange={handleIsTestChange} />
          ),
          comment: element.comment,
          subscription: (
            <IconButton
              onClick={() => {
                setSubscriptionData(element);
                if (!element.is_test) {
                  setDisplaySubscriptionDetailPopup(true);
                }
              }}
              className={`${
                element.subscription && !element.is_test ? 'stroke-secondary font-bold' : 'stroke-black'
              } text-xl cursor-pointer child:mx-auto`}>
              <SubscriptionEuro />
            </IconButton>
          ),
          rate: element.subscription?.rate_title,
          end_subscription: element.subscription?.end_date
            ? moment(element.subscription?.end_date).format('DD/MM/YYYY HH:mm:ss')
            : element.last_subscription?.end_date
            ? moment(element.last_subscription?.end_date).format('DD/MM/YYYY HH:mm:ss')
            : '',
          actions: generateActionButtons(element),
        });
      });
      return rowsData;
    }
  }

  function generateActionButtons(element) {
    return (
      <section className="flex flex-row justify-around">
        <button
          onClick={async () => {
            setDeleteItem(element);
            setDisplayDeletePopup(true);
          }}
          className="text-secondary-blue hover:text-secondary-blue-dark mr-2">
          <Icon className="stroke-warning-red text-2xl">
            <Trash stroke={'#F4304A'} />
          </Icon>
        </button>
        <button
          onClick={() => {
            setEditableRowData(element);
            setDisplayCreditPopup(true);
          }}
          className="text-secondary-blue hover:text-secondary-blue-dark mr-2">
          <Icon className="stroke-black text-2xl">
            <EditText />
          </Icon>
        </button>
        <NavLink to={`/users/${element.id}`} className="text-secondary-blue hover:text-secondary-blue-dark mr-2">
          <Icon className="stroke-primary text-2xl">
            <ExternalLink />
          </Icon>
        </NavLink>
      </section>
    );
  }
};

export default Users;
